import * as React from 'react';
import { graphql, PageProps } from 'gatsby';
import { CSSProperties, styled } from 'linaria/react';
// import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import Logo from '../assets/svg/logo/menu.imp.svg';
import LogoCover from '../assets/svg/logo/info.imp.svg';

const Base = styled.div`
  width: 780px;
  margin: auto;
  background: #b2a06b url('https://media-public.canva.com/MADQ5UTVCJQ/1/screen_2x.jpg');
  background-attachment: fixed;
  background-size: 160% 130%;
  padding: 1em 0;
  @media print {
    background: #fff;
  }
`;

const Page = styled.section`
  @page {
    size: A4 portrait;
    margin: 0mm;
  }
  @media print {
    margin: 0 auto;
  }
  margin: 1em auto;
  width: 750px;
  height: 1050px;
  box-sizing: border-box;
  padding: 5mm 10mm;
  background-color: var(--white);
  page-break-after: always;
  * {
    text-align: center;
  }
`;

const Item = styled.li`
  border-bottom: 1px solid;
  * {
    text-align: left;
  }
`;

const AlXS = styled.p`
  font-family: Cormorant Garamond, serif;
  font-size: 8pt;
  line-height: 1;
`;
const JpL = styled.p`
  font-family: Yuji Syuku, serif;
  font-size: 12pt;
  line-height: 1;
`;
const JpM = styled.p`
  font-family: Yuji Syuku, serif;
  font-size: 10pt;
  line-height: 1;
`;
const JpXS = styled.p`
  font-family: Yuji Syuku, serif;
  font-size: 6pt;
  line-height: 1;
  height: 6pt;
  letter-spacing: -0.5pt;
`;

const Header = ({ color = '#b2a06b' }: { color?: string }) => (
  <header style={{ height: '70px', position: 'relative', marginBottom: '2em' }}>
    <Logo style={{ height: '100%' }} />
    <div
      style={{
        position: 'absolute',
        top: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <h1 style={{ fontFamily: 'Niconne, sans-serif', color }}>Les Vins</h1>
      <p style={{ alignSelf: 'flex-end', fontFamily: 'Cormorant Garamond, Yuji Syuku, serif', fontSize: '14px' }}>
        Les taxes includes.<span style={{ fontSize: '12px' }}>(税込)</span>
      </p>
    </div>
  </header>
);

const Category = ({ alp, jp }: { alp: string; jp: string }) => (
  <div>
    <h3 style={{ fontFamily: 'Niconne, sans-serif', fontSize: '18pt', lineHeight: 1, color: '#b2a06b' }}>{alp}</h3>
    <h2 style={{ fontFamily: 'Yuji Syuku, serif', fontSize: '18pt', lineHeight: 1 }}>{jp}</h2>
  </div>
);
const Region = ({ alp, jp }: { alp: string; jp: string }) => (
  <div style={{ marginTop: '1em', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
    <h3 style={{ fontFamily: 'Niconne, sans-serif', fontSize: '18pt', lineHeight: 1, color: '#b2a06b' }}>{alp}</h3>
    <h2 style={{ fontFamily: 'Yuji Syuku, serif', fontSize: '18pt', lineHeight: 1 }}>{jp}</h2>
  </div>
);

const Cover = () => (
  <Page style={{ padding: '3em', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <LogoCover style={{ width: '75%', margin: '5% 0 5% -20%' }} />
    <h1
      style={{ fontFamily: 'Niconne, sans-serif', color: '#b2a06b', fontSize: '48pt', margin: '1em auto 0.25em auto' }}
    >
      La Cave de Gokita
    </h1>
    <h5
      style={{
        fontFamily: 'Niconne, sans-serif',
        color: '#b2a06b',
        fontSize: '24pt',
        letterSpacing: 5,
        fontWeight: 'lighter',
      }}
    >
      Depuis le 21 Juillet, 2021
    </h5>
    <p style={{ fontFamily: 'Yuji Syuku, serif', fontSize: '20pt', margin: '1em auto' }}>ワインリスト</p>
    <p style={{ fontFamily: 'Niconne, sans-serif', fontSize: '20pt' }}> Le bon vin réjouit le cœur de l'Homme. </p>
  </Page>
);

type WineData = {
  year?: string;
  wineName?: string;
  wineNameJp?: string;
  maker?: string;
  makerJp?: string;
  place?: string;
  placeJp?: string;
  cepage?: string;
  price?: string;
  comment?: string;
};

const List = ({ data, style }: { data: WineData[]; style?: CSSProperties }) => (
  <ul style={{ width: '100%', marginTop: '1.5em', ...style }}>
    {data.map((item, index) => (
      <Item
        key={String(item.wineName) + String(index)}
        style={{ display: 'flex', flexDirection: 'column', padding: '0.25em 0' }}
      >
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1 }}>
            <AlXS>{item.wineName}</AlXS>
            <div style={{ display: 'flex', alignItems: 'baseline' }}>
              <p style={{ fontFamily: 'Economica, sans-serif', lineHeight: 1, fontSize: '10pt', width: '2em' }}>
                {item.year === 'NM' ? 'N.M.' : item.year}
              </p>
              <JpL style={{ height: '2em', overflow: 'hidden', whiteSpace: 'pre-wrap' }}>{item.wineNameJp}</JpL>
            </div>
            <JpXS style={{ margin: '0.25em auto' }}>{item.cepage && `(${item.cepage})`}</JpXS>
          </div>
          <div style={{ flex: 1, display: 'flex' }}>
            <div style={{ flex: 1 }}>
              <AlXS>
                {item.maker}
                {item.place && ` (${item.place})`}
              </AlXS>
              <div style={{ fontSize: '10pt', height: '3em', overflow: 'hidden' }}>
                <JpM style={{ whiteSpace: 'pre-wrap' }}>{item.makerJp}</JpM>
                {item.placeJp && <JpM>({item.placeJp})</JpM>}
              </div>
            </div>
            <JpM
              style={{
                justifySelf: 'end',
                alignSelf: 'center',
                fontFamily: 'Sawarabi Mincho, serif',
              }}
            >
              {new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' }).format(Number(item.price))}
            </JpM>
          </div>
        </div>
        <JpXS>{item.comment}</JpXS>
      </Item>
    ))}
  </ul>
);

type Label = { alp: string; jp: string };

const viewList = (list: WineData[][], category?: Label | null, region?: Label, color = '#b2a06b') =>
  list.length < 1
    ? null
    : list.map((items: WineData[], i: number) => (
        <Page key={String(i)}>
          <Header color={color} />
          {i < 1 && category && <Category alp={category.alp} jp={category.jp} />}
          {i < 1 && region && <Region alp={region.alp} jp={region.jp} />}
          <List data={items} />
        </Page>
      ));

const slice = (array: any[], startLength = 12) => {
  if (array.length < 1) return [];
  if (array.length <= startLength) return [array];

  const res = [array.slice(0, startLength)];

  const rem = array.slice(startLength);
  res.push(...rem.reduce((a, c, i) => (i % 13 === 0 ? [...a, [c]] : [...a.slice(0, -1), [...a[a.length - 1], c]]), []));
  return res;
};

const WineList = ({ data }: PageProps<GatsbyTypes.Query>) => {
  // const device = useBreakpoint();
  // const isSmallViewer = device.m;

  const recommendBlanc = slice([...data.allWineListRecommendBlanc.nodes]);
  const recommendRouge = slice([...data.allWineListRecommendRouge.nodes]);

  const mousseuxChampagne = slice([...data.allWineListMousseuxChampagne.nodes]);
  const mousseuxAutres = slice([...data.allWineListMousseuxAutres.nodes]);
  const blancAlsaceJura = slice([...data.allWineListBlancAlsaceJura.nodes], 11);
  const blancRhone = slice([...data.allWineListBlancRhone.nodes]);
  const blancLoire = slice([...data.allWineListBlancLoire.nodes]);
  const blancBourgogne = slice([...data.allWineListBlancBourgogne.nodes]);
  const blancJp = slice([...data.allWineListBlancJp.nodes]);
  const rougeBourgogne = slice([...data.allWineListRougeBourgogne.nodes], 11);
  const rougeBordeaux = slice([...data.allWineListRougeBordeaux.nodes]);
  const rougeRhone = slice([...data.allWineListRougeRhone.nodes]);
  const rougeAutres = slice([...data.allWineListRougeAutres.nodes]);
  const rougeEtranger = slice([...data.allWineListRougeEtranger.nodes]);
  const rougeJp = slice([...data.allWineListRougeJp.nodes]);

  return (
    <Base>
      <Cover />
      {/* Recommend */}
      {recommendBlanc.length < 1 && recommendRouge.length < 1 ? null : (
        <Page>
          <Header />
          <Category alp="Les Vins recommandé limité" jp="本月おすすめボトルワイン" />
          <Region alp="Les Vins Blanc" jp="白ワイン" />
          <List data={recommendBlanc[0]} style={{ marginBottom: '2em' }} />
          <Region alp="Les Vins Rouge" jp="赤ワイン" />
          <List data={recommendRouge[0]} />
        </Page>
      )}
      {/* mousseux-champagne */}
      {viewList(mousseuxChampagne, { alp: 'Les Champagne', jp: 'シャンパーニュ' })}
      {/* mousseux-autres */}
      {viewList(mousseuxAutres, { alp: 'Les Vin Mousseux Autres', jp: 'スパークリング' })}
      {/* blanc-alsace-jura */}
      {viewList(
        blancAlsaceJura,
        { alp: 'Les Vin Blanc', jp: '白ワイン' },
        { alp: 'Alsace / Jura', jp: 'アルザス / ジュラ地方' },
      )}
      {/* blanc-rhone */}
      {viewList(blancRhone, null, { alp: 'Côtes du Rhône', jp: 'ローヌ地方' })}
      {/* blanc-loire */}
      {viewList(blancLoire, null, { alp: 'Vallée de la Loire', jp: 'ロワール地方' })}
      {/* blanc-bourgogne */}
      {viewList(blancBourgogne, null, { alp: 'Bourgogne', jp: 'ブルゴーニュ' })}
      {/* blanc-jp */}
      {viewList(blancJp, null, { alp: 'Vins du Japon', jp: '日本ワイン' })}
      {/* rouge-bourgogne */}
      {viewList(
        rougeBourgogne,
        { alp: 'Les Vin Rouge', jp: '赤ワイン' },
        { alp: 'Bourgogne', jp: 'ブルゴーニュ' },
        '#b42011',
      )}
      {/* rouge-bordeaux */}
      {viewList(rougeBordeaux, null, { alp: 'Bordeaux', jp: 'ボルドー' }, '#b42011')}
      {/* rouge-rhone */}
      {viewList(rougeRhone, null, { alp: 'Côtes du Rhône', jp: 'ローヌ地方' }, '#b42011')}
      {/* rouge-autres */}
      {viewList(rougeAutres, null, { alp: 'Autres régions', jp: 'その他の地域' }, '#b42011')}
      {/* rouge-etranger */}
      {viewList(rougeEtranger, null, { alp: "L'étranger Région", jp: '世界各地' }, '#b42011')}
      {/* rouge-jp */}
      {viewList(rougeJp, null, { alp: 'Vins du Japo', jp: '日本ワイン' }, '#b42011')}
    </Base>
  );
};

export default WineList;

export const query = graphql`
  query {
    allWineListRecommendBlanc(sort: { order: ASC, fields: price }) {
      nodes {
        year
        price
        wineName
        wineNameJp
        maker
        makerJp
        place
        placeJp
        cepage
        comment
      }
    }
    allWineListRecommendRouge(sort: { order: ASC, fields: price }) {
      nodes {
        year
        price
        wineName
        wineNameJp
        maker
        makerJp
        place
        placeJp
        cepage
        comment
      }
    }
    allWineListMousseuxChampagne(sort: { order: ASC, fields: price }) {
      nodes {
        year
        price
        wineName
        wineNameJp
        maker
        makerJp
        place
        placeJp
        cepage
        comment
      }
    }
    allWineListMousseuxAutres(sort: { order: ASC, fields: price }) {
      nodes {
        year
        price
        wineName
        wineNameJp
        maker
        makerJp
        place
        placeJp
        cepage
        comment
      }
    }
    allWineListBlancAlsaceJura(sort: { order: ASC, fields: price }) {
      nodes {
        year
        price
        wineName
        wineNameJp
        maker
        makerJp
        place
        placeJp
        cepage
        comment
      }
    }
    allWineListBlancRhone(sort: { order: ASC, fields: price }) {
      nodes {
        year
        price
        wineName
        wineNameJp
        maker
        makerJp
        place
        placeJp
        cepage
        comment
      }
    }
    allWineListBlancLoire(sort: { order: ASC, fields: price }) {
      nodes {
        year
        price
        wineName
        wineNameJp
        maker
        makerJp
        place
        placeJp
        cepage
        comment
      }
    }
    allWineListBlancBourgogne(sort: { order: ASC, fields: price }) {
      nodes {
        year
        price
        wineName
        wineNameJp
        maker
        makerJp
        place
        placeJp
        cepage
        comment
      }
    }
    allWineListBlancJp(sort: { order: ASC, fields: price }) {
      nodes {
        year
        price
        wineName
        wineNameJp
        maker
        makerJp
        place
        placeJp
        cepage
        comment
      }
    }
    allWineListRougeBourgogne(sort: { order: ASC, fields: price }) {
      nodes {
        year
        price
        wineName
        wineNameJp
        maker
        makerJp
        place
        placeJp
        cepage
        comment
      }
    }
    allWineListRougeBordeaux(sort: { order: ASC, fields: price }) {
      nodes {
        year
        price
        wineName
        wineNameJp
        maker
        makerJp
        place
        placeJp
        cepage
        comment
      }
    }
    allWineListRougeRhone(sort: { order: ASC, fields: price }) {
      nodes {
        year
        price
        wineName
        wineNameJp
        maker
        makerJp
        place
        placeJp
        cepage
        comment
      }
    }
    allWineListRougeAutres(sort: { order: ASC, fields: price }) {
      nodes {
        year
        price
        wineName
        wineNameJp
        maker
        makerJp
        place
        placeJp
        cepage
        comment
      }
    }
    allWineListRougeEtranger(sort: { order: ASC, fields: price }) {
      nodes {
        year
        price
        wineName
        wineNameJp
        maker
        makerJp
        place
        placeJp
        cepage
        comment
      }
    }
    allWineListRougeJp(sort: { order: ASC, fields: price }) {
      nodes {
        year
        price
        wineName
        wineNameJp
        maker
        makerJp
        place
        placeJp
        cepage
        comment
      }
    }
  }
`;
